import { configureStore } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';

import viewSlice from '../slice/views/viewSlice';
import authenticationSlice from '../slice/authentication/authenticationSlice';
import memberSlice from '../slice/member/memberSlice';
import couponSlice from '../slice/coupon/couponSlice';
import retailerSlice from '../slice/retailer/retailerSlice';
import setOfferSlice from '../slice/setOffer/setOfferSlice';
import setErrorSlice from '../slice/setError/setErrorSlice';

const reducer = {
    viewData: viewSlice,
    retailerData: retailerSlice,
    authentication: authenticationSlice,
    member: memberSlice,
    coupon: couponSlice,
    setOffer: setOfferSlice,
    setError: setErrorSlice
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;