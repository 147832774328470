import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';

import { animateScroll as scroll } from 'react-scroll';

import AllLink from './AllLink';
import Coupon_area from './coupon/Coupon_area';
import Detail from './Detail';
import Menu from './menu/Menu';
import PointCoupon from './point_area/PointCoupon';
import Error from '../error/Error';
import Error_dialog from '../error/Error_dialog';

import { getRetailerFromEnv, urlParamCheck } from './functions/functions';

import { useSelector } from '../../store/store';
import { categoryView } from '../../slice/views/viewSlice';
import { retailerRed } from '../../slice/retailer/retailerSlice';
import { authentication } from '../../slice/authentication/authentication';
import { member } from '../../slice/member/member';
import { setOfferRed } from '../../slice/setOffer/setOfferSlice';
import { setErrorRed } from '../../slice/setError/setErrorSlice';

import {
    reactTagManagerEvClickFloatingBtn,
    reactTagManagerEvClickFloatingBtnBskt,
    reactTagManagerEvClickFloatingBtnUsage,
    reactTagManagerEvViewAllMenu,
    reactTagManagerEvViewBasketList,
    reactTagManagerEvViewUsageHistoryList
} from './functions/functions';
import ErrorNetwork from "../error/Error_Network";

// Add type definitions for view states
enum ViewState {
  HOME = 0,
  DETAIL = 2,
  MENU = 3,
  ERROR = 4,
  BASKET = 5,
  USAGE_HISTORY = 6
}

// Add type for auth and other state data
interface AuthData {
  status: string;
  data: {
    id?: string;
    status?: string;
  };
}

// Add near the top with other interfaces
interface CouponList {
    status?: string;
}

// Add with other interfaces
interface MemberStatus {
    status: string;
    data: {
        status?: string;
    };
}

const Main: React.FC = () => {
    const dispatch = useAppDispatch();
    // Use enum for viewState
    const viewState = useSelector((state) => state.viewData.categoryViewState) as ViewState;
    const auth = useSelector((state) => state.authentication) as AuthData;
    const offerSetStatus = useSelector((state) => state.setOffer.offerStatus);
    const errorStatus = useSelector((state) => state.setError.errorStatus);
    const couponList = useSelector((state) => state.coupon.data) as CouponList;
    const memberStatus = useSelector((state) => state.member) as MemberStatus;

    const pramcheck = urlParamCheck();
    const retailerFromEnv = getRetailerFromEnv()

    // Extract reusable components
    const SetInformationMessage = () => (
        <div className="set-information">
            <p className="information-text">
                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
            </p>
        </div>
    );

    const FloatingButton = () => (
        <div className="floating-button border border-solid text-center font-bold fixed" onClick={scrollToTop}>
            <span><img className="inline-block align-baseline" src="images/icon-angleUp.png" alt="" /></span>
        </div>
    );

    // Separate scroll handler logic
    const scrollToTop = () => {
        scroll.scrollToTop();
        const userId = auth.data.id || '';
        
        switch (viewState) {
            case ViewState.HOME:
                reactTagManagerEvClickFloatingBtn(userId);
                break;
            case ViewState.BASKET:
                reactTagManagerEvClickFloatingBtnBskt(userId);
                break;
            case ViewState.USAGE_HISTORY:
                reactTagManagerEvClickFloatingBtnUsage(userId);
                break;
        }
    };

    useEffect(() => {
        let actionPayload = {
            categoryViewState: 0,
            category: '0',
            detail: '',
            detailStatus: '',
            preViewState: ''
        };

        let logo_pic_url: any = process.env.REACT_APP_RETAILER_PIC;
        let org_id: any = process.env.REACT_APP_RETAILER_ORGID;

        if (!(pramcheck.status)) {
            actionPayload.categoryViewState = 4;
            dispatch(categoryView(actionPayload));
            dispatch(setErrorRed({ errorStatus: 'error-param' }));
        } else {
            dispatch(retailerRed({ logoUrl: retailerFromEnv.retailer_pic, retailerId: retailerFromEnv.retailer_id }));
        }

        if (auth.status === 'loading' && (memberStatus.status === 'idle' && !Object.keys(memberStatus.data).length)) {
            dispatch(authentication(pramcheck));
        }

        if (!window.navigator.onLine) {
            dispatch(setErrorRed({ errorStatus: 'error-network' }));
        }
    }, []);

    useEffect(() => {
        if (pramcheck.status && auth.status !== 'loading' && ('status' in auth.data && auth.data.status === 'Not Found')) {
            if (memberStatus.status !== 'loading' && !Object.keys(memberStatus.data).length) {
                dispatch(member(pramcheck));
            } else {
                if (memberStatus.status !== 'loading' && memberStatus.data.status != 'failed')
                    setTimeout(()=>{
                        dispatch(authentication(pramcheck))
                    }, 1000)

            }
        }

        if (offerSetStatus.indexOf('set') === -1) {
            window.scrollTo(0, 0);
        }

        if (offerSetStatus === 'set') {
            setTimeout(() => {
                dispatch(setOfferRed({ offerId: '', offerStatus: 'setTimeOut' }));
            }, 6000);
        }

        if (viewState === 3) {
            if (!errorStatus) {
                reactTagManagerEvViewAllMenu(auth.data.id || '');
            }
        }

        if (viewState === 5) {
            if (!errorStatus) {
                reactTagManagerEvViewBasketList(auth.data.id || '');
            }
        }

        if (viewState === 6) {
            if (!errorStatus) {
                reactTagManagerEvViewUsageHistoryList(auth.data.id || '');
            }
        }
    });

    // Separate render logic for authenticated state
    const renderAuthenticatedContent = () => {
        switch (viewState) {
            case ViewState.HOME:
                return (
                    <>
                        <div className="main">
                            <div className="inner_container">
                                <AllLink />
                                {errorStatus.indexOf('error') === -1 && <Coupon_area />}
                            </div>
                        </div>
                        {couponList.status === 'idle' && <FloatingButton />}
                        {offerSetStatus === 'set' && errorStatus.indexOf('error') === -1 && <SetInformationMessage />}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
            case ViewState.DETAIL:
                return (
                    <>
                        <div className='wrapper detail-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <Detail /> : <></>}
                                </div>
                            </div>
                        </div>
                        {offerSetStatus === 'set' ? <SetInformationMessage /> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
            case ViewState.MENU:
                return (
                    <>
                        <div className='wrapper menu-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <Menu /> : <></>}
                                </div>
                            </div>
                        </div>
                        {offerSetStatus === 'set' ? <SetInformationMessage /> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
            case ViewState.ERROR:
                return (
                    <div className="wrapper error-wrapper">
                        <Error />
                    </div>
                );
            case ViewState.BASKET:
            case ViewState.USAGE_HISTORY:
                return (
                    <>
                        <div className='wrapper basket-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <PointCoupon /> : <></>}
                                </div>
                            </div>
                        </div>
                        {'status' in couponList && (couponList.status === 'idle') &&
                            <FloatingButton />
                        }
                        {offerSetStatus === 'set' ? <SetInformationMessage /> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
            default:
                return (
                    <>
                        <div className="main usage-history-wrapper">
                            <div className="inner_container">
                                <AllLink />
                                {errorStatus.indexOf('error') === -1 ? <Coupon_area /> : <></>}
                            </div>
                        </div>
                        {'status' in couponList && (couponList.status === 'idle') &&
                            <FloatingButton />
                        }
                        {offerSetStatus === 'set' ? <SetInformationMessage /> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
        }
    };

    // Render logic for non-authenticated state
    const renderNonAuthenticatedContent = () => {
        if (errorStatus.indexOf('error') === -1) {
            return null;
        }
        
        if (errorStatus.indexOf('error-network') > -1) {
            return (
                <div className="wrapper error-wrapper">
                    <ErrorNetwork />
                </div>
            );
        }
        
        return (
            <div className="wrapper error-wrapper">
                <Error />
            </div>
        );
    };

    return auth.status === 'idle' && 'id' in auth.data 
        ? renderAuthenticatedContent()
        : renderNonAuthenticatedContent();
};

export default Main;