import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from "axios";

import { reactTagManagerEvClickWebCampaignDetail, reactTagManagerEvClickSetBtn } from '../functions/functions';
import { store, useSelector } from '../../../store/store';
import { setOfferRed } from '../../../slice/setOffer/setOfferSlice';
import { setErrorRed } from '../../../slice/setError/setErrorSlice';

// Define proper types
interface CouponBtnProps {
    couponBtnDataProp: {
        id: string;
        webCampaign?: boolean;
        detail_url?: string;
        seted?: boolean;
        used?: boolean;
    }
}

interface BasketPayload {
    retailer_id: string;
    offer_id: string;
}

const CouponBtn: React.FC<CouponBtnProps> = ({ couponBtnDataProp }) => {
    const dispatch = useDispatch();
    const [btnState, setBtnState] = useState<'onset' | 'seted'>('onset');
    const [loading, setLoading] = useState(false);

    const auth = useSelector((state: any) => state.authentication);
    const retailer = useSelector((state: any) => state.retailerData);

    const offerSet = async (id: string, accessToken: string, retailerId: string, offerId: string) => {
        const headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY || '',
            'Authorization': `Bearer ${accessToken}`
        };

        const sendData: BasketPayload[] = [{
            retailer_id: retailerId,
            offer_id: offerId
        }];

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/basket`,
                method: 'POST',
                headers,
                data: sendData
            });

            if (response.status === 200) {
                dispatch(setOfferRed({ offerId: couponBtnDataProp.id, offerStatus: 'set' }));
                setBtnState('seted');
            } else {
                dispatch(setErrorRed({ errorStatus: 'errorSet-basket' }));
            }
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            dispatch(setErrorRed({ errorStatus: 'errorSet-basket' }));
            console.error('Error setting offer:', error);
        }
    };

    const handleWebCampaignClick = () => {
        if (couponBtnDataProp.detail_url) {
            window.location.href = couponBtnDataProp.detail_url;
            reactTagManagerEvClickWebCampaignDetail(couponBtnDataProp.id, auth.data.id);
        }
    };

    const handleSetOffer = () => {
        setLoading(true);
        offerSet(auth.data.id, auth.data.access_token, retailer.retailerId, couponBtnDataProp.id);
        reactTagManagerEvClickSetBtn(couponBtnDataProp.id, auth.data.id);
    };

    if (couponBtnDataProp.webCampaign) {
        return (
            <div className="campaign-button">
                <span onClick={handleWebCampaignClick}>詳しくはこちら</span>
            </div>
        );
    }

    if (couponBtnDataProp.seted || btnState === 'seted') {
        return (
            <div className="coupon-button setted-button">
                <span>
                    <img src="images/icon-setted.svg" alt="セット済アイコン" /> セット済
                </span>
            </div>
        );
    }

    if (couponBtnDataProp.used) {
        return (
            <div className="coupon-button used-button">
                <span>利用済</span>
            </div>
        );
    }

    return (
        <div className="coupon-button">
            <button 
                disabled={loading} 
                onClick={handleSetOffer}
                aria-label="クーポンをセット"
            >
                <img src="images/icon-set.svg" alt="セットアイコン" /> セット
            </button>
        </div>
    );
};

export default CouponBtn;