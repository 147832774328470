import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import CouponBtn from './CouponBtn';
import { useSelector, RootState } from '../../../store/store';
import { useOnScreen } from '../functions/useOnScreen';
import { detailPageView } from '../../../slice/views/viewSlice';
import { setOfferRed } from '../../../slice/setOffer/setOfferSlice';
import {
    momentDateDiffcheck,
    momentMDFmt,
    reactTagManagerEvClickOfferFrame,
    reactTagManagerEvClickBasketItemFrame,
    reactTagManagerEvClickUsageHistoryItem,
    reactTagManagerEvClickWebCampaignDetail
} from '../functions/functions';

interface CouponProps {
    couponItemDataProp: {
        offer?: any;
        id: string;
        started_at: string;
        ended_at: string;
        user_quota: number;
        seted?: boolean;
        used?: boolean;
        webCampaign?: boolean;
        detail_url?: string;
        picture_url: string;
        title: string;
        subtitle?: string;
        discount_value: number;
        between?: boolean;
    }
}

const Coupon: React.FC<CouponProps> = ({ couponItemDataProp }) => {
    const dispatch = useDispatch();
    const logoUrl = useSelector((state: RootState) => state.retailerData.logoUrl);
    const viewState = useSelector((state: RootState) => state.viewData.categoryViewState);
    const viewObj = useSelector((state: RootState) => state.viewData);
    const auth = useSelector((state: RootState) => state.authentication as unknown) as { data: { id: string } };

    const targetRef = useRef(null);
    const componentView = useOnScreen(targetRef);

    const viewData = couponItemDataProp.offer || couponItemDataProp;
    
    const endDay = momentMDFmt(viewData.ended_at);
    const isNew = momentDateDiffcheck(viewData.started_at) >= -7;
    const isExpired = momentDateDiffcheck(viewData.ended_at) < 0;
    const isInfinite = viewData.user_quota >= 2;

    const authId = auth.data?.id || '';

    const handleClick = () => {
        if (viewData.webCampaign) {
            window.location.href = viewData.detail_url;
            reactTagManagerEvClickWebCampaignDetail(viewData.id, authId);
            return;
        }

        dispatch(detailPageView({
            categoryViewState: 2,
            category: viewObj.category,
            detail: viewData.id,
            detailStatus: viewData.seted ? 'seted' : viewData.used ? 'used' : '',
            preViewState: ''
        }));
        dispatch(setOfferRed({ offerId: '', offerStatus: 'none' }));

        // Track click events based on view state
        if (viewState === 0) reactTagManagerEvClickOfferFrame(viewData.id, authId);
        if (viewState === 5) reactTagManagerEvClickBasketItemFrame(viewData.id, authId);
        if (viewState === 6) reactTagManagerEvClickUsageHistoryItem(viewData.id, authId);
    };

    const renderCouponContent = () => (
        <div className="coupon">
            <div className="toDetail" onClick={handleClick}>
                <div className="retailer-logo">
                    <img src={logoUrl} alt="" />
                </div>
                <div className="coupon-info">
                    <div className="coupon-image">
                        <img src={viewData.picture_url} alt="" />
                    </div>
                    <div className="coupon-text">
                        <div className="coupon-use-info">
                            <div className="validity-date">
                                <p>{endDay}<span>まで</span></p>
                            </div>
                            <div className="coupon-use-info-left">
                                <div className="info">
                                    <p>{isInfinite ? '何回でも' : `${viewData.user_quota}回のみ`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="main-title flex justify-center">
                            {viewData.webCampaign ? (
                                <p className="campaign-title font-bold whitespace-nowrap overflow-ellipsis overflow-hidden webCampaign">
                                    {viewData.title}
                                </p>
                            ) : (
                                <>
                                    <img className="h-6" src="images/waonA.png" alt="" />
                                    <p className="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                                        {viewData.discount_value.toLocaleString()}<span className="text-xs">pt</span>
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="sub-title">
                            <p>{viewData.webCampaign ? viewData.subtitle : viewData.title}</p>
                        </div>
                    </div>
                </div>
            </div>
            <CouponBtn couponBtnDataProp={viewData} />
        </div>
    );

    return (
        <div className="coupon-list-item" ref={targetRef} data-id={viewData.id}>
            {renderCouponContent()}
            {isNew && <img className="new-coupon" src={`${process.env.PUBLIC_URL}/images/new.svg`} alt="" />}
            {((viewState === 5 || viewState === 6) && (!viewData.between || isExpired)) && (
                <div className='expired-coupon-mask' onClick={handleClick}>
                    <p>有効期限が<br />終了しました</p>
                </div>
            )}
        </div>
    );
};

export default Coupon;