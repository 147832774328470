import React, { useEffect, useState } from 'react';
import { useAppDispatch, useSelector } from '../../../store/store';
import { basketGet, momentDateDiffcheck, reactTagManagerEvViewOfferList } from '../functions/functions';
import Coupon from './Coupon';
import { coupon } from '../../../slice/coupon/coupon';
import { setErrorRed } from '../../../slice/setError/setErrorSlice';
import { category_info } from '../../../data/data';

interface CouponItem {
  id: string;
  started_at: string;
  ended_at: string;
  user_quota: number;
  webCampaign: boolean;
  categories: Array<{ id: number }>;
  picture_url: string;
  title: string;
  discount_value: number;
  detail_url?: string;
  subtitle?: string;
  between?: boolean;
}

interface BasketItem {
  offer: {
    id: string;
  };
}

interface CouponParams {
  memberId: string;
  accessToken: string;
  retailerId: string;
}

interface AuthData {
    id: string;
    access_token: string;
}

interface CouponListState {
    status: string;
    data: CouponItem[];
}

const CouponArea: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedCategory = useSelector((state) => state.viewData.category);
    const couponList = useSelector((state) => state.coupon.data) as CouponListState;
    const auth = useSelector((state) => state.authentication.data) as AuthData;
    const retailerId = useSelector((state) => state.retailerData.retailerId);
    const viewState = useSelector((state) => state.viewData.categoryViewState);

    const [basketItems, setBasketItems] = useState<BasketItem[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const loadCouponsAndBasket = async () => {
            if (selectedCategory === 'web') {
                setIsLoaded(true);
                return;
            }

            const couponParams: CouponParams = {
                memberId: auth.id,
                accessToken: auth.access_token,
                retailerId
            };

            try {
                await dispatch(coupon(couponParams));
                const basketData = await basketGet(auth.id, auth.access_token, retailerId);
                
                if ('response' in basketData) {
                    dispatch(setErrorRed({ errorStatus: 'errorGet-basket' }));
                    setBasketItems([]);
                } else {
                    setBasketItems(basketData);
                }
            } catch (error) {
                dispatch(setErrorRed({ errorStatus: 'errorGet-basket' }));
            } finally {
                setIsLoaded(true);
            }
        };

        loadCouponsAndBasket();

        if (viewState === 0) {
            reactTagManagerEvViewOfferList(auth.id);
        }
    }, [dispatch, auth, retailerId, selectedCategory, viewState]);

    // Handle coupon list error
    useEffect(() => {
        if (couponList.status === 'failed') {
            dispatch(setErrorRed({ errorStatus: 'errorGet-offer' }));
        }
    }, [couponList.status, dispatch]);

    const filterCouponsByCategory = (coupon: CouponItem) => {
        if (momentDateDiffcheck(coupon.started_at) > 0) return false;

        switch (selectedCategory) {
            case '0':
                return true;
            case '1':
                return momentDateDiffcheck(coupon.started_at) >= -7;
            case 'set':
                return !coupon.webCampaign && !basketItems.some(item => item.offer.id === coupon.id);
            case 'web':
                return coupon.webCampaign;
            default:
                const catInfoJson = JSON.parse(category_info);
                if (catInfoJson[selectedCategory] === 'その他の') {
                    return coupon.webCampaign || 
                           coupon.categories.some(cat => cat.id.toString() === selectedCategory);
                }
                return coupon.categories.some(cat => cat.id.toString() === selectedCategory);
        }
    };

    if (!isLoaded) return null;

    const filteredCoupons = couponList.data
        .map(coupon => ({
            ...coupon,
            seted: basketItems.some(item => item.offer.id === coupon.id)
        }))
        .filter(filterCouponsByCategory);

    if (filteredCoupons.length === 0) {
        return (
            <div className="coupon-list-area">
                <div className="none-coupon">
                    <p>この条件のクーポンはありません。</p>
                </div>
            </div>
        );
    }

    return (
        <div className="coupon-list-area">
            <div className="coupon-list-items">
                {filteredCoupons.map((coupon, index) => (
                    <Coupon couponItemDataProp={coupon} key={index} />
                ))}
            </div>
        </div>
    );
};

export default CouponArea;