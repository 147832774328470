import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse, CancelToken } from 'axios';

import { ApiResponse } from '../../type/couponProps'
import {hashCode} from "../../component/main/functions/functions";

// import { api_url } from '../../data/data';

// Define interface for the response data
interface MemberResponse {
    data: {
        // Add the expected properties from your API response
        [key: string]: any; // This is a temporary type, replace with actual properties
    }
}

// Define interfaces for the request and response
interface RequestConfig {
    url: string;
    method: string;
    headers: any;
    id: string | number;
    data: any;
}

interface RequestResponse {
    data: {
        data: any;  // Replace 'any' with your actual response type
    }
}

export const member = createAsyncThunk<ApiResponse, {status:boolean, orgId:string, memberId:string, retailerId:string}>(
    'member',
    async ({orgId, memberId, retailerId}) => {
        try {
            const orgIdMemberId = orgId.toString() + memberId.toString();
            const email = orgIdMemberId + '@catalina.com';

            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY
            };

            const holders = [
                {
                    "ref": orgIdMemberId,
                    "retailer_id": retailerId
                }
            ];

            const startTime = new Date().getTime();
            const pendingRequests:any = {};
            const makeCancellable = (headers: any, requestId: string | number) => {
                if (!requestId) {
                    return headers;
                }
                console.log(pendingRequests)

                if (pendingRequests[requestId]) {
                    // cancel an existing request
                    pendingRequests[requestId].cancel();
                }
                const source = axios.CancelToken.source();
                const newHeaders = {
                    ...headers,
                    cancelToken: JSON.stringify(source.token)
                };
                pendingRequests[requestId] = source;
                return newHeaders;
            };

            const request = async (obj: RequestConfig): Promise<RequestResponse> => {
                const requestConfig = {
                    url: obj.url,
                    method: obj.method,
                    headers: makeCancellable(headers || {}, obj.id),
                    data: obj.data
                };

                try {
                    const res = await axios.request(requestConfig);
                    delete pendingRequests[obj.id];
                    return { data: res.data };
                } catch (error) {
                    delete pendingRequests[obj.id];
                    if (axios.isCancel(error)) {
                        console.log(`A request to url ${obj.url} was cancelled`);
                        throw error;
                    } else {
                        throw error;
                    }
                }
            };

            const response = await request({
                url: process.env.REACT_APP_MEMBERS_URL || '',
                method: 'POST',
                headers: headers,
                id: email,
                data: {
                    "email": email,
                    "password": hashCode(email),
                    "holders": holders
                }
            });
            const elapsed = new Date().getTime() - startTime;
            console.log('member ' + elapsed);

            return {
                status: 'idle',
                data: response.data,
            };
        }
        catch (e:any) {
            console.log(e);
            return {
                status: 'failed',
                data: e,
            };
        }
    }
);